import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Blurhash } from "react-blurhash";

function RealisationCard({ image, duration, key }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = image;
  }, [image]);

  return (
    <motion.div
      whileInView={{ x: [150, 0] }}
      transition={{ duration: duration }}
      key={key}
      className="overflow-hidden p-[1px] max-w-[250px] bg-black bg-opacity-70 rounded-sm"
    >
      <div style={{ display: imageLoaded ? "none" : "inline" }}>
        <Blurhash
          hash="LkLN}f4pM^D}o_J.r=wI~VR}IXV}"
          width="100%"
          height="200px"
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      </div>
      <img
        src={image}
        style={{ display: !imageLoaded ? "none" : "inline" }}
        alt="Portfolio images"
        className="cursor-pointer animation duration-300 object-scale-down rounded-sm hover:scale-110 max-h-72"
      />
    </motion.div>
  );
}

export default RealisationCard;
