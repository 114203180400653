import React from "react";
import { FaBriefcase } from "react-icons/fa";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import RealisationCard from "../../../components/Cards/RealisationCard";
import Landinglayout from "../../../layouts/Landinglayout";
import Data from "./Data";
import styles from "./realisation.module.css";

const icon = <FaBriefcase color="#f5f5f5" size={25} />;

function Realisation() {
  
  return (
    <Landinglayout>
      <div
        className={`${styles.realisation} flex flex-col gap-2 md:mt-7 px-4 ${styles.infront}`}
      >
        {icon}
        {Data?.map((item, key) => {
          return (
            <div className={` flex flex-col gap-4 mb-4`} key={key}>
              <div className="flex flex-col">
                <span className="font-white font-semibold uppercase tex-[22px] text-yellow-300">
                  {item.title}
                </span>
                <span>{item.description}</span>
              </div>
              <div className="p-1 flex flex-row flex-wrap gap-1">
                {item.images &&
                  item.images.map((item2, key) => {
                    return (
                      <RealisationCard
                        key={key}
                        duration={item2.duration}
                        image={item2.image}
                      />
                    );
                  })}
              </div>
            </div>
          );
        })}

      </div>
    </Landinglayout>
  );
}

export default Realisation;
