import React from "react";
import IMAGES from "../../../constants/images";
import Landinglayout from "../../../layouts/Landinglayout";
import styles from "../styles.module.css";

function About() {
  return (
    <Landinglayout>
      <div
        className={`h-full w-full items-center justify-center flex flex-col md:flex-row gap-12 md:mt-7 px-4 ${styles.infront}`}
      >
        <div className="items-center justify-center flex flex-row">
          <div
            // data-aos="fade-right"
            data-aos-duration="1500"
            className="w-64 h-64 bg-gray-800 border-4 border-gray-400 mt-28 md:mt-10 p-1 rounded-full flex flex-row justify-center items-center hero-image"
          >
            <img
              src={IMAGES.PP}
              loading="lazy"
              data-aos="zoom-in"
              data-aos-duration="1500"
              className="rounded-full -mt-[60px]"
              alt="Ma pp"
            />
          </div>
        </div>
        <div className="flex flex-col gap-3 md:w-[40%] justify-center">
          <div className="flex flex-col w-full">
            <span className="text-white text-2xl font-semibold pb-1">
              Hello 👋, I'm Sergeo Moffo
            </span>
            <span className="text-yellow-300 font-medium">
              Bilingual Fullstack Developer
            </span>
          </div>
          <div className="text-gray-300 text-justify font-regular italic">
            Driven by passion, love and a great desire to see what seems
            impossible be made possible, I have choosen to make of software
            development my day to day job. Eager to learn, share, hardworking,
            working well both in a team environment as well as using own
            initiative, I can conceive, design and develop robust softwares
            while adhering to strict deadline
          </div>
        </div>
      </div>
    </Landinglayout>
  );
}

export default About;
