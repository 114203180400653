import React from "react";
import Landinglayout from "../../../layouts/Landinglayout";
import Data from "./Data";
import { FaGraduationCap } from "react-icons/fa";
import Card from "./../../../components/Cards/FormationCard";
import styles from "../styles.module.css";

const icon1 = <FaGraduationCap color="#f5f5f5" size={40} />;

function Formation() {
  return (
    <Landinglayout>
      <div className={`flex flex-col gap-2 md:mt-7 px-4 ${styles.infront}`}>
        {Data.map((val, key) => {
          return (
            <Card
              duration={val.duration}
              key={key}
              icon={icon1}
              title={val.title}
              company={val.company}
              environnement={val.environnement}
              year={val.year}
              desc={val.desc}
              logo={val.logo}
            />
          );
        })}
      </div>
    </Landinglayout>
  );
}

export default Formation;
