import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import PageLoading from "./utils/Pageloading";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return <PageLoading />;
}

export default App;
