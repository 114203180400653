import React, { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import styles from "./navbar.module.css";

function Landingnavbar() {
  // For Scroll effect
  const [navbar, setNavbar] = useState(false);

  // For Hamburger
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);
  const closeMobileMenu = () => setOpen(false);

  const changeBackground = () => {
    if (window.scrollY >= 20) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();

    window.addEventListener("scroll", changeBackground);
  }, []);
  return (
    <div
      className={`${styles.navbar} ${
        navbar ? styles.navbar_change : "bg-transparent"
      } drop-shadow-md flex flex-col justify-center items-center`}
    >
      <div className="container_fluid flex flex-row justify-between items-center pt-2 pb-2">
        <div className="brand w-1/2 flex flex-row items-center md:gap-1 md:w-1/4"></div>
        {!open ? (
          <div
            className={`md:invisible ${styles.hamburger}`}
            onClick={handleOpen}
          >
            <div className="rounded-full bg-white p-3 cursor-pointer shadow">
              <FaBars className="text-gray-800" />
            </div>
          </div>
        ) : (
          <div
            className={`md:invisible ${styles.hamburger}`}
            onClick={closeMobileMenu}
          >
            <div className="rounded-full bg-white p-3 cursor-pointer shadow">
              <FaTimes className="text-gray-800" />
            </div>
          </div>
        )}

        <div
          className={`flex flex-row md:w-3/4 justify-between md:inline-flex`}
        >
          <div className="flex flex-col justify-center">
            <ul
              className={` ${
                open
                  ? styles.responsive_navbar
                  : styles.nav_items +
                    " " +
                    "list-none invisible md:inline-flex flex items-center gap-8"
              }`}
            >
              <li className={styles.nav_active}>
                <Link to="/">A propos</Link>
              </li>
              <li className="hover:text-gray-400">
                <Link to="/formation">Formations</Link>
              </li>
              <li className="hover:text-gray-400">
                <Link to="/experience">Experiences</Link>
              </li>
              <li className="hover:text-gray-400">
                <Link to="/competence">Comptences</Link>
              </li>
              <li className="hover:text-gray-400">
                <Link to="/realisation">Réalisation</Link>
              </li>
              <li className="hover:text-gray-400">
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>

          <div
            className={`${styles.contact_buttons} flex md:inline-flex justify-end items-right`}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default Landingnavbar;
