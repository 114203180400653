import React from "react";
import styles from "./styles.module.css";
import { motion } from "framer-motion";

function FormationCard(props) {
  return (
    <motion.div
      whileInView={{ x: [150, 0], opacity: [0, 1] }}
      transition={{ duration: props.duration, ease: "easeInOut" }}
      className={`${styles.timeline__item} mb-9 border-b-2 pt-6 pb-12 border-t-2 border-l- border-r-8 rounded-md border-b-gray-500 border-l-gray-300 border-t-gray-500 flex flex-col md:flex-row justify-between pr-4`}
    >
      <div className="pb-4">
        {props.icon}
        <span className="text-gray-400 text-[14px]">{props.year}</span>
        <div className="flex flex-col md:flex-row gap-2 md:items-center">
          <span className="text-yellow-200">Diplôme:</span>
          <h3 className="font-semibold text-white text-xl">{props.title}</h3>
        </div>
        <div className="flex flex-col md:flex-row gap-2">
          <span className="text-yellow-200">Description: </span>
          <span>{props.desc}</span>
        </div>
      </div>
    </motion.div>
  );
}

export default FormationCard;
