import React from "react";
import Landinglayout from "../../../layouts/Landinglayout";
import {
  BsEnvelopeFill,
  BsFillTelephoneFill,
  BsGithub,
  BsLinkedin,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Contact() {
  return (
    <Landinglayout>
      <div className="h-full flex flex-col gap-4 py-20 md:py-44 px-1 md:px-28">
        {/* Contact Informations */}
        <motion.div
          whileInView={{ x: [150, 0], opacity: [0, 1] }}
          transition={{
            duration: 0.8,
            ease: "easeOut",
          }}
        >
          {/* Message */}
          <div className="font-bold text-xl md:text-3xl">
            You are interested about my services ? Leave me a message
          </div>
          <div className="backdrop-blur-sm py-4 px-1 flex flex-row gap-8 sm:items-center justify-center md:justify-between rounded-md">
            {/* Email */}
            <Link className="flex flex-col gap-1">
              <div className="flex gap-1">
                <BsEnvelopeFill size={22} />
                <span className="font-medium text-[18px] hidden md:block">
                  Email
                </span>
              </div>
              <span className="text-gray-400 text-[15px] font-regular hidden md:block">
                sergeomoffo1@gmail.com
              </span>
            </Link>
            {/* LinkedIn */}
            <Link
              to="https://www.linkedin.com/in/sergeo-selagsa-moffo-138439198"
              className="flex flex-col gap-1"
            >
              <div className="flex gap-1">
                <BsLinkedin size={22} target="_blank" />
                <span className="font-medium text-[18px] hidden md:block">
                  LinkedIn
                </span>
              </div>
              <div className="flex flex-col -mt-">
                <span className="text-gray-400 text-[15px] font-regular hidden md:block">
                  in/sergeomoffo
                </span>
              </div>
            </Link>
            {/* Github */}
            <div className="flex flex-col gap-1">
              <div className="flex gap-1">
                <BsGithub size={22} />
                <span className="font-medium text-[18px] hidden md:block">
                  Github
                </span>
              </div>
              <div className="flex flex-col -mt-">
                <span className="text-gray-400 text-[15px] font-regular hidden md:block">
                  @legenie1
                </span>
              </div>
            </div>
            {/* Phone */}
            <Link to="" className="flex flex-col gap-1">
              <div className="flex gap-1">
                <BsFillTelephoneFill size={22} />
                <span className="font-medium text-[18px] hidden md:block">
                  Phone
                </span>
              </div>
              <div className="flex flex-col -mt-">
                <span className="text-gray-400 text-[15px] font-regular hidden md:block">
                  +(237) 690 653 506
                </span>
              </div>
            </Link>
          </div>
        </motion.div>

        {/* Contact Form */}
        <motion.div
          whileInView={{ x: [150, 0], opacity: [0, 1] }}
          transition={{
            duration: 0.8,
            ease: "easeOut",
          }}
          className="flex flex-col gap-4"
        >
          <div className="flex flex-col md:flex-row gap-4">
            <div className="md:w-[50%] w-full">
              <input
                type="text"
                className="w-full bg-transparent outline-none border-[2px] border-white placeholder:text-gray-400 p-3 px-2 rounded-sm focus:border-yellow-100"
                placeholder="Enter your name"
              />
            </div>
            <div className="md:w-[50%] w-full">
              <input
                type="text"
                className="w-full bg-transparent outline-none border-[2px] border-white placeholder:text-gray-400 p-3 px-2 rounded-sm focus:border-yellow-100"
                placeholder="Enter your email"
              />
            </div>
          </div>

          <hr />
          <div className="w-full">
            <textarea
              name="message"
              placeholder="Enter your message"
              className="w-full bg-transparent outline-none border-[2px] border-white placeholder:text-gray-400 p-3 px-2 rounded-sm focus:border-yellow-100"
              id=""
              rows="6"
            ></textarea>
          </div>
        </motion.div>

        {/* SUbmit button */}
        <motion.div
          whileInView={{ y: [40, 0], opacity: [0, 1] }}
          transition={{
            duration: 0.8,
            ease: "easeOut",
          }}
          className="flex justify-center"
        >
          <button className="animation duration-300 bg-white p-3 border-[1px] px-10 text-gray-900 outline-none hover:bg-transparent hover:text-white">
            Send now
          </button>
        </motion.div>
      </div>
    </Landinglayout>
  );
}

export default Contact;
