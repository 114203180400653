import React from "react";
import { FaBriefcase } from "react-icons/fa";
import Landinglayout from "../../../layouts/Landinglayout";
import styles from "../styles.module.css";
import Card from "./../../../components/Cards/ExperienceCard";
import Data from "./Data";

const icon2 = <FaBriefcase color="#f5f5f5" size={37} />;

function Experience() {
  return (
    <Landinglayout>
      <div className={`flex flex-col gap-2 md:mt-7 px-4 ${styles.infront}`}>
        {Data.map((val, key) => {
          return (
            <Card
              duration={val.duration}
              key={key}
              icon={icon2}
              title={val.title}
              company={val.company}
              environnement={val.environnement}
              year={val.year}
              desc={val.desc}
              logo={val.logo}
            />
          );
        })}
      </div>
    </Landinglayout>
  );
}

export default Experience;
