import React, { useState, useEffect } from "react";
import Pageloader from "../components/loaders/Pageloader";
import Routing from "../Routing";

function PageLoading() {
  const [loading, setLoading] = useState(true);

  // We check if our page is still loading
  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);

      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return loading ? <Pageloader /> : <Routing />;
}

export default PageLoading;
