import React from "react";
import { BsEyeFill } from "react-icons/bs";
import { MdOutlineMenuBook, } from "react-icons/md";
import Landinglayout from "../../../layouts/Landinglayout";

function Blog() {

  const blogs = [
    {
      id: 0,
      title: "Working with Spring boot and Apache Kafka",
      views: 117,
      read: 7,
      link: "",
      image: "https://cdn.hashnode.com/res/hashnode/image/upload/v1590356129602/EjGAts8KB.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    },
    {
      id: 1,
      title: "How to keep you Dev Team Motivated Daily",
      views: 23,
      read: 4,
      link: "",
      image: "https://cdn.hashnode.com/res/hashnode/image/upload/v1580688246490/PaXsOof1k.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    },
    {
      id: 2,
      title: "Senior Level Rest Api with Spring boot and OpenApi",
      views: 45,
      read: 9,
      link: "",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrha5GnePDDPEIyFH9dL8Srq1y35oUg1MVWl9bPgVfgs2tpa9KoEffy72UgsVOWhk_8tY&usqp=CAU",
    },
    {
      id: 3,
      title: "Complete Guide to use MongoDb with Spring Boot",
      views: 42,
      read: 5,
      link: "",
      image: "https://zarin.io/codelabs/springboot-api/img/62237878e051374b.jpeg",
    },
    {
      id: 4,
      title: "Best Nodejs setup practices for Mid-Level Developer",
      views: 103,
      read: 7,
      link: "",
      image: "https://miro.medium.com/v2/resize:fit:1017/1*UvALqIe_qkUp2x8SmSiSwQ.png",
    },
    {
      id: 5,
      title: "Becoming a senior Frontend Developer (React.js)",
      views: 66,
      read: 12,
      link: "",
      image: "https://media.licdn.com/dms/image/D5612AQHSONjiC_COsw/article-cover_image-shrink_720_1280/0/1691237436149?e=2147483647&v=beta&t=BaTPnukG-6IavUojvPg4PQPpvCu9Q4xKF_APUMfzW6s",
    },
    {
      id: 6,
      title: "Advantages of using a Proxy Server in your projects",
      views: 148,
      read: 4,
      link: "",
      image: "https://www.fortinet.com/content/fortinet-com/en_us/resources/cyberglossary/proxy-server/_jcr_content/par/c05_container_copy_c/par/c28_image_copy_copy_.img.jpg/1625683502431.jpg",
    },
  ]

  return (
    <Landinglayout>
      <div
        className={`h-full w-full mb-28 py-4`}
      >
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 gap-x-8 mb-16 mt-4">
      {
        blogs?.map(item=>{
          return <div key={item.id} className='flex flex-col gap-4 mb-8'>
            <div className="h-52 overflow-hidden rounded-lg shadow-md cursor-pointer group hover:bg-opacity-40 relative">
              <div className="h-full w-full bg-gray-800/10 transition-colors group-hover:bg-transparent absolute"></div>
              <img loading="lazy"
              className="w-full h-full object-cover duration-300 group-hover:scale-110" src={item?.image} alt="" srcset="" />
            </div>
            <div className="flex flex-col gap-2">
              <span className='text-xl font-bold'>{item?.title.substring(0, 65)}</span>
              <div className="flex gap-2 text-sm text-gray-400">
              <div className="flex items-center gap-1">
              <MdOutlineMenuBook />
              <span>{item.read} min read</span>
              </div>
              <div className="flex items-center gap-1">
              <BsEyeFill />
              <span>{item.views} views</span>
              </div>
              </div>
            </div>
          </div>
        })
       }
      </div>
      </div>
    </Landinglayout>
  );
}

export default Blog;
