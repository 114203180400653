import IMAGES from "../../../constants/images";

const Data = [
  {
    title: "MyMonth",
    description:
      "MyMonth is Freelancing platform for young Cameroonians, specifically to help students gain company experience while still studying",
    images: [
      {
        image: IMAGES.MyMonth1,
        duration: 0.8,
      },
      {
        image: IMAGES.MyMonth2,
        duration: 0.9,
      },
      {
        image: IMAGES.MyMonth3,
        duration: 1.0,
      },
    ],
    link: "mymonth.vercel.app",
    duration: 0.8,
  },
  {
    title: "Composcan",
    description:
      "Composcan is a web and mobile employee clocking application used by small or medium firms",
    images: [
      {
        image: IMAGES.Compo1,
        duration: 0.8,
      },
      {
        image: IMAGES.Compo2,
        duration: 0.9,
      },
      {
        image: IMAGES.Compo3,
        duration: 1.0,
      },
      {
        image: IMAGES.Compo4,
        duration: 1.1,
      },
    ],
    link: "campagne.africanwits.org",
    duration: 0.8,
  },
  {
    title: "SCSM",
    description:
      "SCSM is a statistic consulting survey company, i helped mounting up their website an internal management and survey platform",
    images: [
      {
        image: IMAGES.Scsm1,
        duration: 0.8,
      },
      {
        image: IMAGES.Scsm2,
        duration: 0.9,
      },
      {
        image: IMAGES.Scsm3,
        duration: 1.0,
      },
      {
        image: IMAGES.Scsm4,
        duration: 1.1,
      },
    ],
    link: "campagne.africanwits.org",
    duration: 0.8,
  },
  {
    title: "JiFa",
    description:
      "JiFa is a flyer generator platform for the AfricanWits that permits them automomatically generate flyers for their events",
    images: [
      {
        image: IMAGES.WORK3a,
        duration: 0.8,
      },
      {
        image: IMAGES.WORK3b,
        duration: 0.9,
      },
    ],
    link: "campagne.africanwits.org",
    duration: 0.8,
  },
  {
    title: "Github profile",
    description:
      "Implemented a simple projet where i manipulated the Github API for various requests",
    images: [
      {
        image: IMAGES.Git1,
        duration: 0.8,
      },
      {
        image: IMAGES.Git2,
        duration: 0.9,
      },
    ],
    link: "campagne.africanwits.org",
    duration: 0.8,
  },
  {
    title: "Medico Web",
    description:
      "Medico is a web and mobile app that helps camerounian know the online drug store, with a possibility of buying and ask for a delivery via the platform",
    images: [
      {
        image: IMAGES.WORK1a,
        duration: 0.8,
      },
    ],
    link: "campagne.africanwits.org",
    duration: 0.8,
  },
  {
    title: "Medico Mobile",
    description:
      "Medico is a web and mobile app that helps camerounian know the online drug store, with a possibility of buying and ask for a delivery via the platform",
    images: [
      {
        image: IMAGES.WORK2a,
        duration: 0.8,
      },
      {
        image: IMAGES.WORK2b,
        duration: 0.9,
      },
      {
        image: IMAGES.WORK2c,
        duration: 1.0,
      },
      {
        image: IMAGES.WORK2d,
        duration: 1.1,
      },
      {
        image: IMAGES.WORK2e,
        duration: 1.2,
      },
    ],
    link: "campagne.africanwits.org",
    duration: 0.8,
  },
  {
    title: "G-School",
    description:
      "G-School is a school and Elearning platform available for teachers, schools and students",
    images: [
      {
        image: IMAGES.WORK4a,
        duration: 0.8,
      },
      {
        image: IMAGES.WORK4b,
        duration: 0.9,
      },
      {
        image: IMAGES.WORK4c,
        duration: 1.0,
      },
      {
        image: IMAGES.WORK4d,
        duration: 1.1,
      },
    ],
    link: "campagne.africanwits.org",
    duration: 0.8,
  },
  {
    title: "G-Money",
    description:
      "G-Money is an online njangi management platform for associations, families and individuals",
    images: [
      {
        image: IMAGES.WORK5a,
        duration: 0.8,
      },
      {
        image: IMAGES.WORK5b,
        duration: 0.9,
      },
      {
        image: IMAGES.WORK5c,
        duration: 1.0,
      },
    ],
    link: "campagne.africanwits.org",
    duration: 0.8,
  },
  {
    title: "MyBook",
    description:
      "This is a Digital LogBook System, available for secondary and university institutions",
    images: [
      {
        image: IMAGES.WORK6a,
        duration: 0.8,
      },
      {
        image: IMAGES.WORK6b,
        duration: 0.9,
      },
      {
        image: IMAGES.WORK6c,
        duration: 1.0,
      },
    ],
    link: "campagne.africanwits.org",
  },
  {
    title: "Cabinet Edec",
    description:
      "Conceived a responsive website for Edec sarl to help improve their visibility over the internet",
    images: [
      {
        image: IMAGES.WORK7a,
        duration: 0.8,
      },
      {
        image: IMAGES.WORK7b,
        duration: 0.9,
      },
      {
        image: IMAGES.WORK7c,
        duration: 1.0,
      },
      {
        image: IMAGES.WORK7d,
        duration: 1.1,
      },
    ],
    link: "campagne.africanwits.org",
  },
  {
    title: "Master Entreprise",
    description:
      "Conceived a responsive website for Master Entreprise to help improve their visibility over the internet",
    images: [
      {
        image: IMAGES.WORK8a,
        duration: 0.8,
      },
      {
        image: IMAGES.WORK8b,
        duration: 0.9,
      },
      {
        image: IMAGES.WORK8c,
        duration: 1.0,
      },
    ],
    link: "campagne.africanwits.org",
    duration: 0.8,
  },
];

export default Data;
