import IMAGES from "../../../constants/images";

const Data = [
  {
    icon: "icon-briefcase",
    year: "Nov 2022 - present",
    company: "Nylstech Digital",
    environnement:
      "Springboot, Microservices, Nodejs, Reactjs,React Native, Redux, GraphQl, Git, etc",
    title: "Mid-Level Developer",
    desc: "Leading a team of 4 Developers, Leading projects from design to Deployment, following the agile methodology and respecting strict deadlines",
    logo: IMAGES.NYLSTECH,
    duration: 0.8,
  },
  {
    icon: "icon-briefcase",
    year: "Jan 2022 - Oct 2022",
    company: "E-Solutions",
    environnement:
      "Springboot, Microservices, Nodejs, Reactjs, React Native, Redux, GraphQl, Git, etc",
    title: "Fullstack Developer",
    desc: "Working collaboratively with a team of developers and designers to build and maintain robust software",
    logo: IMAGES.AFRICANWITS,
    duration: 0.9,
  },
  {
    icon: "icon-briefcase",
    year: "Fev 2021 - Dec 2021",
    company: "Mveng Engineering",
    environnement:
      "Springboot, Microservices, Nodejs, Reactjs, React Native, Redux, Intellij, VsCode, etc",
    title: "Fullstack Developer",
    desc: "Working mainly on projects from scratch by following all phases and good development practices in order to provide quality products to its customers.",
    logo: IMAGES.MVENG,
    duration: 1.0,
  },
  {
    icon: "icon-briefcase",
    year: "Fev 2019 - Dec 2021",
    company: "Asm Afrik",
    environnement: "JQuery, Vanillajs, Nodejs, Reactjs, VsCode",
    title: "Fullstack Developer",
    desc: "I worked under the supervision of Senior developers to build API's and integrate user friendly web and mobile user interfaces.",
    logo: IMAGES.GESTA,
    duration: 1.1,
  },
  {
    icon: "icon-briefcase",
    year: "Nov 2018 - Dec 2018",
    company: "Gesta",
    environnement: "Vanillajs, Php, JQuery, Ajax, Nodejs, Laravel, VsCode",
    title: "Fullstack Intern",
    desc: "As an intern, i was responsible of building API's and User friendly UI's.",
    logo: IMAGES.GESTA,
    duration: 1.2,
  },
];

export default Data;
