import React from "react";
import Landinglayout from "../../../layouts/Landinglayout";
import styles from "../styles.module.css";
// Images import frontend web
import html from "../../../images/htmlcss.png";
import javascript from "../../../images/Javascript.svg";
import jquery from "../../../images/jquery.png";
import typescript from "../../../images/Typescript.svg";
import react from "../../../images/react.png";
import redux from "../../../images/redux.png";
import sass from "../../../images/sass.png";
import bootstrap from "../../../images/bootstrap.png";
import tailwind from "../../../images/Tailwindcss.svg";
import mui from "../../../images/mui.png";

// Images for Backend
import spring from "../../../images/spring.png";
import nodejs from "../../../images/nodejs.png";
import php from "../../../images/PHP.svg";
import laravel from "../../../images/Laravel.svg";
import python from "../../../images/python.png";
import fastapi from "../../../images/fastapi.png";

// Images for Design
import reactnative from "../../../images/reactnative.png";

// Images for DevOps
import git from "../../../images/git.png";
import gitlab from "../../../images/Gitlab.svg";
import aws3 from "../../../images/aws3.png";
import docker from "../../../images/Docker.svg";
import jenkins from "../../../images/jenkins.png";
import azure from "../../../images/azuredevops.png";
import groovy from "../../../images/groovy.png";
import cypress from "../../../images/cypress.png";
import mockito from "../../../images/mockito.png";
import selenium from "../../../images/Selenium.png";
import { motion, AnimatePresence } from "framer-motion";

// Images for Design
import adobexd from "../../../images/Adobe-xd.svg";
import photoshop from "../../../images/photoshop.png";

const web = [
  {
    label: "Html & Css",
    icon: html,
    years: new Date().getFullYear() - 2017,
    color: "#f06529",
    duration: 0.8,
  },
  {
    label: "Javascript",
    icon: javascript,
    years: new Date().getFullYear() - 2018,
    color: "#f7df1e",
    duration: 0.9,
  },
  {
    label: "JQuery",
    icon: jquery,
    years: new Date().getFullYear() - 2019,
    color: "#0868ab",
    duration: 1.0,
  },
  {
    label: "Reactjs",
    icon: react,
    years: new Date().getFullYear() - 2019,
    color: "#7fdde9",
    duration: 1.1,
  },
  {
    label: "Typescript",
    icon: typescript,
    years: new Date().getFullYear() - 2021,
    color: "#007acd",
    duration: 1.2,
  },
  {
    label: "Redux",
    icon: redux,
    years: new Date().getFullYear() - 2021,
    color: "#7d57c1",
    duration: 1.3,
  },
  {
    label: "Sass",
    icon: sass,
    years: new Date().getFullYear() - 2019,
    color: "#f06292",
    duration: 1.4,
  },
  {
    label: "Bootstrap",
    icon: bootstrap,
    years: new Date().getFullYear() - 2019,
    color: "#7910f7",
    duration: 1.5,
  },
  {
    label: "Tailwindcss",
    icon: tailwind,
    years: new Date().getFullYear() - 2020,
    color: "#19b5b9",
    duration: 1.6,
  },
  {
    label: "Material UI",
    icon: mui,
    years: new Date().getFullYear() - 2020,
    color: "#007fff",
    duration: 1.6,
  },
];

const backend = [
  {
    label: "Nodejs",
    icon: nodejs,
    years: new Date().getFullYear() - 2019,
    color: "#21a366",
    duration: 0.9,
  },
  {
    label: "Spring boot",
    icon: spring,
    years: new Date().getFullYear() - 2020,
    color: "#6cb33e",
    duration: 0.8,
  },
  {
    label: "Php",
    icon: php,
    years: new Date().getFullYear() - 2017,
    color: "#6181b6",
    duration: 1.0,
  },
  {
    label: "Laravel",
    icon: laravel,
    years: new Date().getFullYear() - 2019,
    color: "#ff2d20",
    duration: 1.1,
  },
  {
    label: "Python",
    icon: python,
    years: new Date().getFullYear() - 2020,
    color: "#ffc107",
    duration: 1.2,
  },
  {
    label: "FastApi",
    icon: fastapi,
    years: new Date().getFullYear() - 2021,
    color: "#0b9b8d",
    duration: 1.3,
  },
];

const mobile = [
  {
    label: "React Native",
    icon: reactnative,
    years: new Date().getFullYear() - 2020,
    color: "#00a7d4",
    duration: 0.8,
  },
];

const devops = [
  {
    label: "Git",
    icon: git,
    years: new Date().getFullYear() - 2020,
    color: "#f4511e",
    duration: 0.8,
  },
  {
    label: "Gitlab CI/CD",
    icon: gitlab,
    years: new Date().getFullYear() - 2021,
    color: "#fca326",
    duration: 0.9,
  },
  {
    label: "AWS Drive",
    icon: aws3,
    years: new Date().getFullYear() - 2022,
    color: "#d35142",
    duration: 1.0,
  },
  {
    label: "Docker",
    icon: docker,
    years: new Date().getFullYear() - 2021,
    color: "#0091e2",
    duration: 1.1,
  },
  {
    label: "Jenkins",
    icon: jenkins,
    years: new Date().getFullYear() - 2022,
    color: "#f0d6b7",
    duration: 1.2,
  },
  {
    label: "Azure DevOps",
    icon: azure,
    years: new Date().getFullYear() - 2022,
    color: "#0c67b6",
    duration: 1.3,
  },
  {
    label: "Groovy",
    icon: groovy,
    years: new Date().getFullYear() - 2022,
    color: "#5c95b4",
    duration: 1.4,
  },
  {
    label: "Cypress",
    icon: cypress,
    years: new Date().getFullYear() - 2022,
    color: "#f5f5f5",
    duration: 1.5,
  },
  {
    label: "Selenium",
    icon: selenium,
    years: new Date().getFullYear() - 2022,
    color: "#25a162",
    duration: 1.6,
  },
  {
    label: "Mockito + JUnit",
    icon: mockito,
    years: new Date().getFullYear() - 2021,
    color: "#25a162",
    duration: 1.7,
  },
];

const design = [
  {
    label: "Adobe xD",
    icon: adobexd,
    years: new Date().getFullYear() - 2020,
    color: "#ff2bc2",
    duration: 0.8,
  },
  {
    label: "Photoshop",
    icon: photoshop,
    years: new Date().getFullYear() - 2018,
    color: "#00aafe",
    duration: 0.9,
  },
];

function Competence() {
  return (
    <Landinglayout>
      <div className={`flex flex-col gap-12 md:mt-7 px-4 ${styles.infront}`}>
        {/* Web Development */}
        <div>
          <motion.h2
            whileInView={{ x: [-40, 0], opacity: [0, 1] }}
            transition={{
              duration: 0.8,
              ease: "easeInOut",
            }}
            className="mb-8 font-semibold text-[20px] -ml-5"
          >
            Web Development
          </motion.h2>

          <div className="flex flex-wrap gap-6">
            {web &&
              web.map((item, key) => {
                return (
                  <AnimatePresence>
                    <motion.div
                      className="flex flex-col justify-center items-center gap-2"
                      key={item.label}
                      whileInView={{ x: [150, 0], opacity: [0, 1] }}
                      transition={{
                        duration: item.duration,
                        ease: "easeInOut",
                      }}
                    >
                      <div
                        className={`group animation duration-300 hover:scale-110 w-24 h-24 p-2 cursor-pointer flex flex-row items-center justify-center ${"bg-red-200"}  bg-opacity-20 rounded-full`}
                      >
                        <img
                          src={item.icon}
                          width="50%"
                          className="animation duration-300 group-hover:scale-75"
                          alt=""
                        />
                      </div>

                      <div className="flex flex-col text-center">
                        <span
                          className="font-medium text-lg"
                          style={{ color: item.color }}
                        >
                          {item.label}
                        </span>
                        <span className="text-gray-300 text-[14px]">
                          {item.years} years
                        </span>
                      </div>
                    </motion.div>
                  </AnimatePresence>
                );
              })}
          </div>
        </div>

        {/* Backend Development */}
        <div>
          <motion.h2
            whileInView={{ x: [-40, 0], opacity: [0, 1] }}
            transition={{
              duration: 0.8,
              ease: "easeInOut",
            }}
            className="mb-8 font-semibold text-[20px] -ml-5"
          >
            Backend Development
          </motion.h2>

          <div className="flex flex-wrap gap-6">
            {backend &&
              backend.map((item, key) => {
                return (
                  <motion.div
                    className="flex flex-col justify-center items-center gap-2"
                    key={item.label}
                    whileInView={{ x: [150, 0], opacity: [0, 1] }}
                    transition={{ duration: item.duration, ease: "easeInOut" }}
                  >
                    <div className="group animation duration-300 hover:scale-110 w-24 h-24 p-2 cursor-pointer flex flex-row items-center justify-center bg-red-500 bg-opacity-20 rounded-full">
                      <img
                        src={item.icon}
                        width="50%"
                        className="animation duration-300 group-hover:scale-75"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col text-center">
                      <span
                        className="font-medium text-lg"
                        style={{ color: item.color }}
                      >
                        {item.label}
                      </span>
                      <span className="text-gray-300 text-[14px]">
                        {item.years} years
                      </span>
                    </div>
                  </motion.div>
                );
              })}
          </div>
        </div>

        {/* Backend Development */}
        <div>
          <motion.h2
            whileInView={{ x: [-40, 0], opacity: [0, 1] }}
            transition={{
              duration: 0.8,
              ease: "easeInOut",
            }}
            className="mb-8 font-semibold text-[20px] -ml-5"
          >
            Mobile Development
          </motion.h2>

          <div className="flex flex-wrap gap-6">
            {mobile &&
              mobile.map((item, key) => {
                return (
                  <motion.div
                    className="flex flex-col justify-center items-center gap-2"
                    key={item.label}
                    whileInView={{ x: [150, 0], opacity: [0, 1] }}
                    transition={{ duration: item.duration, ease: "easeInOut" }}
                  >
                    <div className="group animation duration-300 hover:scale-110 w-24 h-24 p-2 cursor-pointer flex flex-row items-center justify-center bg-red-500 bg-opacity-20 rounded-full">
                      <img
                        src={item.icon}
                        width="50%"
                        className="animation duration-300 group-hover:scale-75"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col text-center">
                      <span
                        className="font-medium text-lg"
                        style={{ color: item.color }}
                      >
                        {item.label}
                      </span>
                      <span className="text-gray-300 text-[14px]">
                        {item.years} years
                      </span>
                    </div>
                  </motion.div>
                );
              })}
          </div>
        </div>

        {/* Devops */}
        <div className="mb-4">
          <motion.h2
            whileInView={{ x: [-40, 0], opacity: [0, 1] }}
            transition={{
              duration: 0.8,
              ease: "easeInOut",
            }}
            className="mb-8 font-semibold text-[20px] -ml-5"
          >
            DevOps & Automation
          </motion.h2>

          <div className="w-full flex flex-row flex-wrap gap-6">
            {devops &&
              devops.map((item, key) => {
                return (
                  <motion.div
                    className="flex flex-col justify-center items-center gap-2"
                    key={item.label}
                    whileInView={{ x: [150, 0], opacity: [0, 1] }}
                    transition={{ duration: item.duration, ease: "easeInOut" }}
                  >
                    <div className="group animation duration-300 hover:scale-110 w-24 h-24 p-2 cursor-pointer flex flex-row items-center justify-center bg-red-500 bg-opacity-20 rounded-full">
                      <img
                        src={item.icon}
                        width="50%"
                        className="animation duration-300 group-hover:scale-75"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col text-center">
                      <span
                        className="font-medium text-lg"
                        style={{ color: item.color }}
                      >
                        {item.label}
                      </span>
                      <span className="text-gray-300 text-[14px]">
                        {item.years} years
                      </span>
                    </div>
                  </motion.div>
                );
              })}
          </div>
        </div>

        {/* Design */}
        <div className="mb-4">
          <motion.h2
            whileInView={{ x: [-40, 0], opacity: [0, 1] }}
            transition={{
              duration: 0.8,
              ease: "easeInOut",
            }}
            className="mb-8 font-semibold text-[20px] -ml-5"
          >
            Design
          </motion.h2>

          <div className="w-full flex flex-row flex-wrap gap-6">
            {design &&
              design.map((item) => {
                return (
                  <motion.div
                    className="flex flex-col justify-center items-center gap-2"
                    key={item.label}
                    whileInView={{ x: [150, 0], opacity: [0, 1] }}
                    transition={{ duration: item.duration, ease: "easeInOut" }}
                  >
                    <div className="group animation duration-300 hover:scale-110 w-24 h-24 p-2 cursor-pointer flex flex-row items-center justify-center bg-red-500 bg-opacity-20 rounded-full">
                      <img
                        src={item.icon}
                        width="50%"
                        className="animation duration-300 group-hover:scale-75"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col text-center">
                      <span
                        className="font-medium text-lg"
                        style={{ color: item.color }}
                      >
                        {item.label}
                      </span>
                      <span className="text-gray-300 text-[14px]">
                        {item.years} years
                      </span>
                    </div>
                  </motion.div>
                );
              })}
          </div>
        </div>
      </div>
    </Landinglayout>
  );
}

export default Competence;
