import React from "react";
import styles from "./loader.module.css";

function Pageloader() {
  return (
    <div
      className="absolute h-screen w-screen bg-black flex flex-row"
      style={{ zIndex: 9999 }}
    >
      <svg
        id={styles.page_preloader}
        width="659"
        height="109"
        viewBox="0 0 413 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5062 47C11.9441 47 9.63814 46.4824 7.58841 45.4473C5.53868 44.369 3.92818 42.8594 2.75691 40.9184C1.58564 38.9775 1 36.7131 1 34.1252H7.69822C7.84462 36.0661 8.48516 37.662 9.61983 38.9128C10.7911 40.1636 12.4199 40.789 14.5062 40.789C16.6658 40.789 18.3495 40.1852 19.5574 38.9775C20.7652 37.7267 21.3692 36.1308 21.3692 34.1899C21.3692 32.6803 20.9848 31.451 20.2162 30.5021C19.4841 29.5532 18.5508 28.82 17.4161 28.3024C16.3181 27.7848 14.7808 27.2241 12.8042 26.6203C10.3153 25.8439 8.28385 25.0675 6.70995 24.2911C5.17266 23.4716 3.85498 22.2208 2.75691 20.5387C1.65884 18.8565 1.10981 16.6137 1.10981 13.8101C1.10981 11.2222 1.65884 8.95781 2.75691 7.01688C3.85498 5.07595 5.39227 3.58791 7.3688 2.55274C9.34532 1.51758 11.633 1 14.2317 1C17.9285 1 20.9482 2.09986 23.2908 4.29958C25.6699 6.45617 26.9876 9.43226 27.2438 13.2278H20.326C20.2162 11.5888 19.5574 10.1871 18.3495 9.0225C17.1416 7.85795 15.5494 7.27567 13.5729 7.27567C11.7794 7.27567 10.3153 7.81482 9.18061 8.89311C8.04594 9.9714 7.4786 11.5241 7.4786 13.5513C7.4786 14.9316 7.82632 16.0745 8.52177 16.9803C9.25381 17.8429 10.1689 18.5331 11.2669 19.0506C12.365 19.5682 13.8657 20.1289 15.769 20.7328C18.2946 21.5523 20.3443 22.3718 21.9182 23.1913C23.5287 24.0108 24.883 25.2832 25.981 27.0084C27.1157 28.6906 27.6831 30.955 27.6831 33.8017C27.6831 36.0877 27.1523 38.2443 26.0909 40.2714C25.066 42.2986 23.547 43.9376 21.5339 45.1885C19.5573 46.3962 17.2148 47 14.5062 47Z"
          stroke="white"
          stroke-width="2"
          mask="url(#path-1-outside-1_1_4)"
        />
        <path
          d="M41.3274 7.53446V20.7328H54.5042V26.7496H41.3274V40.5302H56.1513V46.5471H35.0684V1.51758H56.1513V7.53446H41.3274Z"
          stroke="white"
          stroke-width="2"
          mask="url(#path-1-outside-1_1_4)"
        />
        <path
          d="M83.6807 46.5471L74.8961 28.5612H70.1195V46.5471H63.8605V1.58228H77.0374C79.9655 1.58228 82.4362 2.18612 84.4493 3.39381C86.499 4.6015 88.018 6.21894 89.0063 8.24613C90.0312 10.2733 90.5436 12.5377 90.5436 15.0394C90.5436 17.9723 89.8115 20.6465 88.3475 23.0619C86.92 25.4341 84.7055 27.0516 81.7041 27.9142L91.1475 46.5471H83.6807ZM70.1195 22.6737H77.0374C79.3799 22.6737 81.1368 21.9836 82.3081 20.6034C83.516 19.2232 84.1199 17.3685 84.1199 15.0394C84.1199 12.7103 83.5343 10.8987 82.363 9.60478C81.1917 8.2677 79.4165 7.59915 77.0374 7.59915H70.1195V22.6737Z"
          stroke="white"
          stroke-width="2"
          mask="url(#path-1-outside-1_1_4)"
        />
        <path
          d="M126.167 14.5218C125.179 12.2789 123.806 10.5968 122.049 9.47539C120.292 8.31083 118.261 7.72855 115.955 7.72855C113.429 7.72855 111.178 8.39709 109.202 9.73418C107.225 11.0713 105.67 12.9691 104.535 15.4276C103.437 17.8861 102.888 20.7328 102.888 23.9677C102.888 27.2025 103.437 30.0708 104.535 32.5724C105.67 35.0309 107.225 36.9287 109.202 38.2658C111.178 39.6029 113.429 40.2714 115.955 40.2714C119.359 40.2714 122.122 39.15 124.245 36.9072C126.368 34.6643 127.668 31.6235 128.143 27.7848H113.814V21.8973H134.842V27.6554C134.439 31.1491 133.378 34.3624 131.657 37.2954C129.974 40.2283 127.759 42.579 125.014 44.3474C122.305 46.0727 119.286 46.9353 115.955 46.9353C112.368 46.9353 109.092 45.9648 106.127 44.0239C103.162 42.0399 100.802 39.301 99.0446 35.8073C97.3243 32.3136 96.4641 28.3671 96.4641 23.9677C96.4641 19.5682 97.3243 15.6217 99.0446 12.128C100.802 8.63432 103.162 5.91702 106.127 3.97609C109.129 1.99203 112.404 1 115.955 1C120.018 1 123.623 2.18612 126.771 4.55837C129.955 6.88748 132.261 10.2086 133.689 14.5218H126.167Z"
          stroke="white"
          stroke-width="2"
          mask="url(#path-1-outside-1_1_4)"
        />
        <path
          d="M147.274 7.53446V20.7328H160.451V26.7496H147.274V40.5302H162.098V46.5471H141.015V1.51758H162.098V7.53446H147.274Z"
          stroke="white"
          stroke-width="2"
          mask="url(#path-1-outside-1_1_4)"
        />
        <path
          d="M187.211 47C183.661 47 180.385 46.0295 177.384 44.0886C174.419 42.1045 172.058 39.3657 170.301 35.872C168.581 32.3352 167.721 28.3671 167.721 23.9677C167.721 19.5682 168.581 15.6217 170.301 12.128C172.058 8.63432 174.419 5.91702 177.384 3.97609C180.385 1.99203 183.661 1 187.211 1C190.798 1 194.074 1.99203 197.039 3.97609C200.04 5.91702 202.401 8.63432 204.122 12.128C205.842 15.6217 206.702 19.5682 206.702 23.9677C206.702 28.3671 205.842 32.3352 204.122 35.872C202.401 39.3657 200.04 42.1045 197.039 44.0886C194.074 46.0295 190.798 47 187.211 47ZM187.211 40.5949C189.737 40.5949 191.988 39.9264 193.964 38.5893C195.941 37.2091 197.478 35.2682 198.576 32.7665C199.711 30.2218 200.278 27.2888 200.278 23.9677C200.278 20.6465 199.711 17.7351 198.576 15.2335C197.478 12.7318 195.941 10.8125 193.964 9.47539C191.988 8.1383 189.737 7.46976 187.211 7.46976C184.686 7.46976 182.435 8.1383 180.458 9.47539C178.482 10.8125 176.926 12.7318 175.791 15.2335C174.693 17.7351 174.144 20.6465 174.144 23.9677C174.144 27.2888 174.693 30.2218 175.791 32.7665C176.926 35.2682 178.482 37.2091 180.458 38.5893C182.435 39.9264 184.686 40.5949 187.211 40.5949Z"
          stroke="white"
          stroke-width="2"
          mask="url(#path-1-outside-1_1_4)"
        />
        <path
          d="M267.312 1.58228V46.5471H261.053V15.7511L249.414 46.5471H245.077L233.382 15.7511V46.5471H227.123V1.58228H233.876L247.273 36.8425L260.614 1.58228H267.312Z"
          stroke="white"
          stroke-width="2"
          mask="url(#path-1-outside-1_1_4)"
        />
        <path
          d="M292.943 47C289.393 47 286.117 46.0295 283.116 44.0886C280.151 42.1045 277.79 39.3657 276.033 35.872C274.313 32.3352 273.453 28.3671 273.453 23.9677C273.453 19.5682 274.313 15.6217 276.033 12.128C277.79 8.63432 280.151 5.91702 283.116 3.97609C286.117 1.99203 289.393 1 292.943 1C296.53 1 299.806 1.99203 302.771 3.97609C305.772 5.91702 308.133 8.63432 309.854 12.128C311.574 15.6217 312.434 19.5682 312.434 23.9677C312.434 28.3671 311.574 32.3352 309.854 35.872C308.133 39.3657 305.772 42.1045 302.771 44.0886C299.806 46.0295 296.53 47 292.943 47ZM292.943 40.5949C295.469 40.5949 297.72 39.9264 299.696 38.5893C301.673 37.2091 303.21 35.2682 304.308 32.7665C305.443 30.2218 306.01 27.2888 306.01 23.9677C306.01 20.6465 305.443 17.7351 304.308 15.2335C303.21 12.7318 301.673 10.8125 299.696 9.47539C297.72 8.1383 295.469 7.46976 292.943 7.46976C290.418 7.46976 288.167 8.1383 286.19 9.47539C284.214 10.8125 282.658 12.7318 281.523 15.2335C280.425 17.7351 279.876 20.6465 279.876 23.9677C279.876 27.2888 280.425 30.2218 281.523 32.7665C282.658 35.2682 284.214 37.2091 286.19 38.5893C288.167 39.9264 290.418 40.5949 292.943 40.5949Z"
          stroke="white"
          stroke-width="2"
          mask="url(#path-1-outside-1_1_4)"
        />
        <path
          d="M341.049 1.58228V7.59915H324.852V20.8622H337.48V26.879H324.852V46.5471H318.593V1.58228H341.049Z"
          stroke="white"
          stroke-width="2"
          mask="url(#path-1-outside-1_1_4)"
        />
        <path
          d="M369.304 1.58228V7.59915H353.108V20.8622H365.736V26.879H353.108V46.5471H346.849V1.58228H369.304Z"
          stroke="white"
          stroke-width="2"
          mask="url(#path-1-outside-1_1_4)"
        />
        <path
          d="M392.509 47C388.959 47 385.683 46.0295 382.682 44.0886C379.717 42.1045 377.356 39.3657 375.599 35.872C373.879 32.3352 373.019 28.3671 373.019 23.9677C373.019 19.5682 373.879 15.6217 375.599 12.128C377.356 8.63432 379.717 5.91702 382.682 3.97609C385.683 1.99203 388.959 1 392.509 1C396.096 1 399.372 1.99203 402.337 3.97609C405.338 5.91702 407.699 8.63432 409.42 12.128C411.14 15.6217 412 19.5682 412 23.9677C412 28.3671 411.14 32.3352 409.42 35.872C407.699 39.3657 405.338 42.1045 402.337 44.0886C399.372 46.0295 396.096 47 392.509 47ZM392.509 40.5949C395.035 40.5949 397.286 39.9264 399.262 38.5893C401.239 37.2091 402.776 35.2682 403.874 32.7665C405.009 30.2218 405.576 27.2888 405.576 23.9677C405.576 20.6465 405.009 17.7351 403.874 15.2335C402.776 12.7318 401.239 10.8125 399.262 9.47539C397.286 8.1383 395.035 7.46976 392.509 7.46976C389.984 7.46976 387.733 8.1383 385.756 9.47539C383.78 10.8125 382.224 12.7318 381.089 15.2335C379.991 17.7351 379.442 20.6465 379.442 23.9677C379.442 27.2888 379.991 30.2218 381.089 32.7665C382.224 35.2682 383.78 37.2091 385.756 38.5893C387.733 39.9264 389.984 40.5949 392.509 40.5949Z"
          stroke="white"
          stroke-width="2"
          mask="url(#path-1-outside-1_1_4)"
        />
      </svg>

      <div className={styles.spinner__overlay} id={styles.animation__overlay}>
        <div className={styles.spinner__overlay__inner}>
          <div className={styles.spinner}>
            <div
              className={`${styles.spinner__bounce} ${styles.spinner__bounce__1}`}
            ></div>
            <div
              className={`${styles.spinner__bounce} ${styles.spinner__bounce__2}`}
            ></div>
            <div
              className={`${styles.spinner__bounce} ${styles.spinner__bounce__3}`}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pageloader;
