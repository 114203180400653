import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { About, Blog, Competence, Contact, Experience, Formation, Realisation } from "./pages/landing";


function Routing() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<About />} />
        <Route path="/formation" element={<Formation />} />
        <Route path="/experience" element={<Experience />} />
        <Route path="/competence" element={<Competence />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/realisation" element={<Realisation />} />
        <Route path="/blogs" element={<Blog />} />
      </Routes>
    </Router>
  );
}

export default Routing;
