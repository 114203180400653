const Data = [
  // {
  //   year: "2023 - present",
  //   title: "Master in AI",
  //   desc: "Started an online evening Master degree Program at DIT - Senegal to sharpen my skills in.",
  //   duration: 0.8,
  // },
  {
    year: "2021 - 2022",
    title: "ALX - SE",
    desc: "I am an ALX software engineering program graduate, which ended in December 2022.",
    duration: 0.9,
  },
  {
    year: "2021 - 2022",
    title: "Bachelor's Degree",
    desc: "I am a holder of a bachelor's degree in software engineering which i obtained at IUT-FV.",
    duration: 1.0,
  },
  {
    year: "2020 - 2021",
    title: "HND",
    desc: "I later futher my studies at ISIM where i obtained my HND in software engineering.",
    duration: 1.1,
  },
  {
    year: "2019 - 2020",
    title: "GCE Advanced Level",
    desc: "I obtained my advanced in Bertoua Cameroon at GBHS - series S1 with 4 papers.",
    duration: 1.2,
  },
];

export default Data;
