import React from "react";
import Landingnavbar from "../pages/landing/navbar/Landingnavbar";
import Sidebar from "../pages/landing/sidebar/Sidebar";
import styles from "../pages/landing/styles.module.css";

function Landinglayout({ children, title }) {
  return (
    <div title={title ? title : ""} className="w-full h-screen flex flex-row">
      <Sidebar />
      <div
        style={{ zIndex: 9999 }}
        className={`w-full overflow-y-scroll overflow-x-hidden px-4 md:w-4/5 bg-black/90 bg-opacity-70 landing-layout ${styles.background}`}
      >
        <Landingnavbar />
        {children}
      </div>
    </div>
  );
}

export default Landinglayout;
