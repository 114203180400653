export const IMAGES = {
  PP: require("../images/sergeopp.png"),

  // Clients
  MVENG: require("../images/mveng.jpg"),
  AFRICANWITS: require("../images/africanwits.png"),
  NYLSTECH: require("../images/nylstech.png"),
  GESTA: require("../images/gesta.png"),

  // Skills
  HTML: require("../images/html.png"),
  CSS: require("../images/css.png"),
  SASS: require("../images/sass.png"),
  JAVASCRIPT: require("../images/Javascript.svg"),
  TYPESCRIPT: require("../images/typescript.png"),

  // Works
  // medico
  WORK1a: require("../images/works/work-20.png"),
  WORK2a: require("../images/works/work-19.png"),
  WORK2b: require("../images/works/work-23.png"),
  WORK2c: require("../images/works/work-24.png"),
  WORK2d: require("../images/works/work-21.png"),
  WORK2e: require("../images/works/work-22.png"),

  // MyMonth
  MyMonth1: require("../images/works/mymonth1.png"),
  MyMonth2: require("../images/works/mymonth2.png"),
  MyMonth3: require("../images/works/mymonth3.png"),

  // Composcan
  Compo1: require("../images/works/compo1.png"),
  Compo2: require("../images/works/compo2.png"),
  Compo3: require("../images/works/compo3.png"),
  Compo4: require("../images/works/compo4.png"),

  // Scsm
  Scsm1: require("../images/works/scsm1.png"),
  Scsm2: require("../images/works/scsm2.png"),
  Scsm3: require("../images/works/scsm3.png"),
  Scsm4: require("../images/works/scsm4.png"),

  // Flyer Generator
  WORK3a: require("../images/works/work-9.png"),
  WORK3b: require("../images/works/work-10.png"),

  // Flyer Generator
  Git1: require("../images/works/github1.png"),
  Git2: require("../images/works/github2.png"),

  // Gesta School
  WORK4a: require("../images/works/work-1.png"),
  WORK4b: require("../images/works/work-2.png"),
  WORK4c: require("../images/works/work-3.png"),
  WORK4d: require("../images/works/work-4.png"),

  // G-Money
  WORK5a: require("../images/works/work-6.png"),
  WORK5b: require("../images/works/work-7.png"),
  WORK5c: require("../images/works/work-8.png"),

  // MyBook
  WORK6a: require("../images/works/work-14.png"),
  WORK6b: require("../images/works/work-16.png"),
  WORK6c: require("../images/works/work-17.png"),

  // Edec
  WORK7a: require("../images/works/edec1.png"),
  WORK7b: require("../images/works/edec2.png"),
  WORK7c: require("../images/works/edec3.png"),
  WORK7d: require("../images/works/edec4.png"),

  // Edec
  WORK8a: require("../images/works/master1.png"),
  WORK8b: require("../images/works/master2.png"),
  WORK8c: require("../images/works/master3.png"),
};

export default IMAGES;
