import React from "react";
// import { BsSunFill, BsSunrise } from "react-icons/bs";
import { Link } from "react-router-dom";
// import Flag from "react-world-flags";
import styles from "./sidebar.module.css";

function Sidebar() {
  const data = [
    {
      id: 1,
      label: "About Me",
      path: "/",
    },
    {
      id: 2,
      label: "Education",
      path: "/formation",
    },
    {
      id: 3,
      label: "Experiences",
      path: "/experience",
    },
    {
      id: 4,
      label: "Achievements",
      path: "/realisation",
    },
    {
      id: 5,
      label: "Skills",
      path: "/competence",
    },
    {
      id: 6,
      label: "Blogs",
      path: "/blogs",
    },
    {
      id: 7,
      label: "Contact Me",
      path: "/contact",
    },
  ];

  const pathname = document.location.pathname;

  return (
    <div className="hidden md:block w-1/5 h-screen bg-black">
      <div className="flex flex-col bg-blac h-full">
        <div className="h-1/6 flex items-center justify-center gap-4">
          {/* <div className="rounded-full cursor-pointer bg-white hover:bg-gray-200 w-10 h-10 flex items-center justify-center">
            <BsSunFill />
          </div>
          <div className="rounded-full cursor-pointer bg-white hover:bg-gray-200 w-10 h-10 flex items-center justify-center p-1">
            <Flag code="us" width={20} />
          </div> */}
        </div>
        {/* Content Sidebar */}
        <div className="flex h-full flex-col relative justify-center">
          <ul className="list-none flex flex-col gap-5 text-white -mt-12">
            {data &&
              data.map((item) => {
                return (
                  <li
                    key={item.id}
                    className="group relative flex flex-row items-center"
                  >
                    <div
                      className={`${styles} w-[35%] animation ease-in-out duration-300`}
                    >
                      <div
                        className={`${styles.links} ${
                          pathname === item.path
                            ? "w-full bg-gray-200"
                            : "w-0 group-hover:w-full group-hover:bg-gray-200"
                        } `}
                      ></div>
                    </div>
                    <div
                      className={`${
                        pathname === item.path
                          ? "block"
                          : "invisible group-hover:visible"
                      } group-hover:block group-hover:mr-1 bg-white w-[5px] h-[5px] rounded-full animation ease-in-out duration-300`}
                    ></div>
                    <Link
                      to={item.path}
                      className={`${
                        pathname === item.path
                          ? "text-white text-[16px]"
                          : "text-[#BBBBBB] text-[15px]"
                      } ${
                        styles.texts
                      } text-center w-ful group-hover:block group-hover:mr-1 animation ease-in-out duration-300`}
                    >
                      {item.label}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
